<template>
  <v-container class="mt-5 main-content" >
      <div class="Title" >
      <h1 class="text-h5">  お問合せ</h1>
      <div  >
       <v-card
        elevation="2"
        class="mt-7 mx-0 pa-0"
       >
        <TableInquiry />

      </v-card>

      </div>
    </div>
  </v-container>
</template>

<script>
// import Inquiry from '../components/Inquiry.vue'

const TableInquiry = () => import('@/components/TableInquiry.vue');

  export default {
  components: {  TableInquiry },
    name: 'Support',

    data: () => ({
       tab: null,
        items: ['新規', 'お問合せ一覧'],
     
    }),
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }
  .v-toolbar__content {
  padding: 0px !important;
}

</style>

